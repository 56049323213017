import * as React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { KpButton } from '@components/elements/index';
import theme from '@styles/theme';
import { BASKETID, ButtonTypes, PaymentOptions } from '@utils/Constant';
import { validateSignInForm } from './Validator';
import ErrorMessage from './ErrorMessage';
import {
  AuthFormLabel,
  AuthInputMessage,
  AuthSendCode,
  AuthSignupMessage,
  PasswordResetLink,
} from '@styles/Global.forms';
import { useState } from 'react';
import {
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  resendSignUpCode,
  ResendSignUpCodeInput,
  signIn,
  SignInInput,
  SignInOutput,
} from 'aws-amplify/auth';
import { useNavigate } from '@remix-run/react';
// import {
//   getEmptyCheckout,
//   saveDealerAddress,
//   saveDealerAddressToLocalStorage,
//   saveDealerDetails,
//   saveGiftingDetails,
//   saveIsDealerOrder,
//   saveIsDealerOrderToLocalStorage,
//   savePaymentTypeToLocalStorage,
//   savePersonalDetailsToLocalStorage,
//   saveTokenToLocalStorage,
// } from '~/stateManagement/localStorage/checkout';
// import { DealerService } from '~/services/DealerService';
// import { useOrderContext } from '~/contexts/OrderContext';
// import { Checkout } from "~/dataModels/Checkout/Checkout";
// import { getNewBasketIdService } from '~/services/BasketService';
// import { BasketAttributeModel } from '~/dataModels/BasketModel/BasketRequestModel';
// import { processSignOut } from '~/utils/Helpers';
import { useAuthContext } from '~/contexts/AuthContext';

const SignIn = ({
  isDealer = false,
  // apiUrl = '',
}: {
  isDealer?: boolean;
  // apiUrl?: string;
}) => {
  //   const orderContext = useOrderContext();
  //   const { checkout, setCheckout, addBasketAttributes } = orderContext ?? {
  //     checkout: getEmptyCheckout(),
  //   };
  const { isAuthLoading, login, errorMessage } = useAuthContext();
  const [account, setAccount] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  //   const [loading, setLoading] = useState(false);
  const [resendCode, setResendCode] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState(false);
  const navigate = useNavigate();

  const updateAccount = (account: { email: string; password: string }) => {
    setAccount(account);
    setError('');
    setResendCode(false);
    setCodeSent(false);
    setIsLoginDisabled(false);
  };

  const resend = () => {
    resendSignUpCode({ username: account.email } as ResendSignUpCodeInput).then(
      () => {
        setCodeSent(true);
        setError('');
      },
    );
  };

  //   const logoutFirst = async () => {
  //     try {
  //       // logout if user is already logged in
  //       await getCurrentUser();
  //       await processSignOut();
  //     } catch (error) {
  //       // do nothing
  //     }
  //   };

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateSignInForm(account.email, account.password, setError)) {
      return;
    }

    if(isDealer){
      localStorage.clear();
      sessionStorage.clear();
    }
    const res = await login(account.email, account.password);

    if (res) {
      if (
        res.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
      ) {
        navigate('/reset-password/');
      } else {
        if (!isDealer) {
          navigate('/app/listings');
        } else {
          navigate('/dealer-promotions');
        }
      }
    }

    if (!res || !res.isSignedIn) {
      console.error('signin failed', res);
      if(errorMessage) {
        setError(errorMessage)
      } else {
      setError('Incorrect Username and password');      
      }
    }
  };

  //   const handleSignIn = async (e: React.FormEvent) => {
  //     e.preventDefault();
  //     if (!validateSignInForm(account.email, account.password, setError)) {
  //       return;
  //     }

  //     setLoading(true);
  //     await logoutFirst();
  //     signIn({
  //       username: account.email,
  //       password: account.password,
  //     } as SignInInput)
  //       .then(async (res: SignInOutput) => {
  //         if (
  //           res.nextStep.signInStep ===
  //           'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
  //         ) {
  //           navigate('/reset-password/');
  //         }

  //         if (!isDealer) {
  //           setLoading(false);
  //           console.log(res);
  //           navigate('/app/listings');
  //         }

  //         if (!res.isSignedIn) {
  //           console.error('signin failed', res);
  //           return;
  //         }

  //         const { tokens } = await fetchAuthSession();
  //         saveTokenToLocalStorage(tokens?.idToken?.toString() ?? '');

  //         const { email } = await fetchUserAttributes();
  //         const em = email ?? '';
  //         DealerService.updateDealerRegistration(apiUrl, em.trim())
  //           .then((rsp) => {
  //             if (rsp.Status !== 200 && rsp.Status !== 500) {
  //               return;
  //             }
  //             // TODO: extract to server->localstorage sync module
  //             DealerService.getDealer(apiUrl, em.trim())
  //               .then((response) => {
  //                 setLoading(false);
  //                 if (response.Status === 200) {
  //                   var data = response.Data[0];
  //                   checkout.isDealerOrder = true;
  //                   checkout.dealerDetails = {
  //                     dealerId: data.DealerId,
  //                     dealerName: data.DealerName,
  //                     salesPersonEmail: data.SalesPersonEmail,
  //                     salesPersonId: data.SalesPersonId,
  //                     salesPersonName: data.SalesPersonName,
  //                     distributionId: data.CommissionDistribution,
  //                   };
  //                   checkout.dealerAddress = {
  //                     fullAddress: data.FullAddress,
  //                     street: data.Street,
  //                     building: data.Building,
  //                     unit: data.Unit,
  //                     suburb: data.Suburb,
  //                     city: data.City,
  //                     postCode: data.PostCode,
  //                     isManualAddress: true,
  //                   };
  //                   savePersonalDetailsToLocalStorage(checkout);
  //                   saveIsDealerOrderToLocalStorage(checkout.isDealerOrder);
  //                   saveDealerAddressToLocalStorage(checkout.dealerAddress);
  //                   savePaymentTypeToLocalStorage(PaymentOptions.CreditCard);

  //                   updateDealerInfoToContext(checkout);
  //                   navigate('/dealer-promotions');
  //                 }

  //                 setIsLoginDisabled(true);
  //                 setError(response.Message);
  //               })
  //               .catch((exception) => {
  //                 setError(exception.message);
  //                 setLoading(false);
  //               });
  //           })
  //           .catch((exp) => {
  //             setError(exp.message);
  //             setLoading(false);
  //           });
  //       })
  //       .catch((exception) => {
  //         if (exception.code == 'UserNotConfirmedException') {
  //           //TODO: Check if this is the correct code
  //           setResendCode(true);
  //         }
  //         setError(exception.message);
  //         setLoading(false);
  //       });
  //   };

  //   const updateDealerInfoToContext = async (checkout: Checkout) => {
  //     setCheckout(checkout);

  //     let basketId = '';
  //     const response = await getNewBasketIdService(apiUrl);
  //     if (response) {
  //       basketId = response;
  //       localStorage.setItem(BASKETID, basketId);
  //     } else {
  //       throw new Error('Failed to create new basket', response);
  //     }

  //     let attributes: BasketAttributeModel[] = [];
  //     attributes.push(saveIsDealerOrder(checkout.isDealerOrder));
  //     attributes.push(...saveDealerDetails(checkout.dealerDetails));
  //     attributes.push(...saveDealerAddress(checkout.dealerAddress));

  //     addBasketAttributes(basketId, attributes);
  //   };

  return (
    <Form onSubmit={handleSignIn}>
      <Form.Field>
        <AuthFormLabel>EMAIL</AuthFormLabel>
        <Input
          type="email"
          name="email"
          value={account.email}
          id="email"
          placeholder="yourname@email.com"
          onChange={(e) => updateAccount({ ...account, email: e.target.value })}
          autoComplete="new-password"
          className={error && 'red-border'}
        />
      </Form.Field>
      <Form.Field>
        <AuthFormLabel>PASSWORD</AuthFormLabel>
        <AuthInputMessage>
          <PasswordResetLink
            to={isDealer ? '/dealer-forgot-password' : '/forgot-password'}
          >
            Forgot your password?
          </PasswordResetLink>
        </AuthInputMessage>
        <Input
          type="password"
          name="password"
          value={account.password}
          id="password"
          className={error && 'red-border'}
          onChange={(e) =>
            updateAccount({ ...account, password: e.target.value })
          }
          autoComplete="new-password"
        />
      </Form.Field>
      {error && <ErrorMessage errorMessage={error} />}
      {resendCode && (
        <div style={{ textAlign: 'center', paddingTop: '10px' }}>
          <AuthSignupMessage>
            Didn't verify your email address?
          </AuthSignupMessage>
          <AuthSendCode onClick={resend}>Re-send!</AuthSendCode>
        </div>
      )}
      {codeSent && (
        <div style={{ textAlign: 'center', paddingTop: '10px' }}>
          <AuthSignupMessage>
            Please check your email to verify your email address.
          </AuthSignupMessage>
        </div>
      )}
      <Form.Field style={{ textAlign: 'center', padding: '10px 0' }}>
        <KpButton
          id="signin-submit"
          color={theme.brand.colors.green}
          fullWidth="mobile tablet computer"
          buttonType={ButtonTypes.Primary}
          loading={isAuthLoading}
          type="submit"
          disabled={isLoginDisabled}
        >
          {isAuthLoading ? 'Loading...' : 'Log in'}
        </KpButton>
      </Form.Field>
      {isDealer && (
        <div style={{ textAlign: 'center', paddingTop: '10px' }}>
          <p>
            Don't have an account? Or having trouble logging in? Please call our
            dealer team on <a href="tel:0800650111">0800 650 111</a> or email us
            at <a href="mailto:dealers@kiwiplates.nz">dealers@kiwiplates.nz</a>
          </p>
        </div>
      )}
    </Form>
  );
};

export default SignIn;
